const appConfig = {
  // apiPrefix: "http://localhost:5002/api/",
  apiPrefix: "https://vktestcar.5techg.com/api/",
  // apiPrefix: "https://vehicle-uku3.onrender.com/api/",
  webhookPrefix: "https://webhooks.vkrepo.in/webhooks/",
  authenticatedEntryPath: "/dashboard",
  unAuthenticatedEntryPath: "/sign-in",
};

export default appConfig;
